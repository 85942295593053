export default [
  {
    postId: '7168228307946176513',
    image:
      'https://raw.githubusercontent.com/Adheretech/adheretech.com/t_linkedin-feed/src/images/linkedin/01.jpg?token=GHSAT0AAAAAACMJRQP5HV6BADM57LSKO6VWZPABFIQ',
    text: `Medication nonadherence continues to be the elephant in Healthcare's room. Join us as we take a dive into the how, what, and why of medication nonadherence.`,
  },
  {
    postId: '7166096202034311169',
    image:
      'https://raw.githubusercontent.com/Adheretech/adheretech.com/t_linkedin-feed/src/images/linkedin/02.jpg?token=GHSAT0AAAAAACMJRQP5NSJ37DNGQKTPMWUGZPABFXQ',
    text: `We're excited to welcome Paul Sekhri to our Board of Directors.`,
  },
  {
    postId: '7086815037956206592',
    image:
      'https://raw.githubusercontent.com/Adheretech/adheretech.com/t_linkedin-feed/src/images/linkedin/03.jpg?token=GHSAT0AAAAAACMJRQP4IET2LWSE7KVKZFRUZPABGEA',
    text: `We are happy to be a part of the health tech ecosystem that is changing patient adherence for the better!
    Check out this article providing great expert insights regarding the continued need and resources available to improve patient adherence. The value of even the greatest innovative drug therapy cannot be met if patients are poor at compliance.
    #adherence #adheretech #AT
    https://lnkd.in/eqjqfCVT`,
  },
].sort((a, b) => +b.postId - +a.postId)
