import React from 'react'
import { Link } from 'gatsby'
import Hero from '../components/Hero'
import CallToAction from '../components/CallToAction'
import CallToActionLink from '../components/CallToActionLink'
import LinkedInFeed from '../components/LinkedInFeed'

const HomePage = () => {
  return (
    <main className="home-page">
      <Hero heading="Better health through adherence.">
        <p>Because every dose — and every day — counts.</p>
      </Hero>
      <div className="page-section">
        <div className="page-section-content">
          <h2>Medication non-adherence: the elephant in the room</h2>
        </div>
        <div className="page-section-content">
          <p className="final-p">
            The first step outside of the doctor&apos;s office is one of the largest unsolved problems in healthcare.
            Beyond this point, a patient&apos;s own medication-taking behavior becomes the central factor in their
            health, <strong>but visibility into this is lacking.</strong>
          </p>
        </div>
        <div className="page-section-content">
          <CallToActionLink color="blue" linkUrl="/the-adherence-problem" linkText="Learn more" />
        </div>
      </div>
      <div className="page-section">
        <div className="call-to-action__container">
          <div className="page-section-content call-to-action-grid fadeIn animated">
            <CallToAction
              headerText="Get to know the behavioral change system that makes adherence effortless"
              linkText="See How It Works"
              linkUrl="/how-aidia-works"
              color="green"
            >
              <strong>≥95%</strong> Patient Satisfaction, over <strong>36,000 Patients</strong> served.
            </CallToAction>
            <CallToAction
              headerText="Partners with a patient’s medicine to lift retention >50% within first year"
              linkText="See The Evidence"
              linkUrl="/evidence"
              color="blue"
            >
              Our programs routinely <strong>increase the number of prescriptions</strong> by three within the first 12
              months.
            </CallToAction>
          </div>
        </div>
      </div>
      <div className="page-section--blue">
        <div className="page-section-content faq">
          <p>
            ¿Necesita ayuda con su frasco inteligente de pastillas de Aidia?{' '}
            <Link to="/faqs?lang=ES">Consulte nuestra página de preguntas frecuentes</Link>
          </p>
        </div>
      </div>

      <div className="page-section linkedin-feed">
        <h2>Join the conversation as we revolutionize adherence</h2>
        <LinkedInFeed />
        <CallToActionLink
          color="blue"
          linkUrl="https://www.linkedin.com/company/adheretech/"
          external
          linkText="Follow us on LinkedIn"
        />
      </div>
    </main>
  )
}
export default HomePage
