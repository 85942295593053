export default function timeDifference(time, minimumUnit) {
  if (typeof time === 'undefined' || time === null) return null

  const units = ['minute', 'hour', 'day', 'month', 'year']

  const current = new Date().getTime()
  const previous = new Date(time).getTime()

  const milliSecondsPerMinute = 60 * 1000
  const milliSecondsPerHour = milliSecondsPerMinute * 60
  const milliSecondsPerDay = milliSecondsPerHour * 24
  const milliSecondsPerMonth = milliSecondsPerDay * 30
  const milliSecondsPerYear = milliSecondsPerDay * 365

  const elapsed = current - previous

  if (elapsed < 0) {
    return 'in the future'
  }

  if (elapsed < milliSecondsPerMinute / 3) {
    return 'just now'
  }

  let value
  if (elapsed < milliSecondsPerMinute && units.indexOf(minimumUnit) <= units.indexOf('minute')) {
    return '< 1 minute ago'
  } else if (elapsed < milliSecondsPerHour && units.indexOf(minimumUnit) <= units.indexOf('minute')) {
    value = Math.round(elapsed / milliSecondsPerMinute)
    if (value < 1) return '< 1 minute ago'
    return `${value} minute${value > 1 ? 's' : ''} ago`
  } else if (elapsed < milliSecondsPerDay && units.indexOf(minimumUnit) <= units.indexOf('hour')) {
    value = Math.round(elapsed / milliSecondsPerHour)
    if (value < 1) return '< 1 hour ago'
    return `${value} hour${value > 1 ? 's' : ''} ago`
  } else if (elapsed < milliSecondsPerMonth && units.indexOf(minimumUnit) <= units.indexOf('day')) {
    value = Math.round(elapsed / milliSecondsPerDay)
    if (value < 1) return '< 1 day ago'
    return `${value} day${value > 1 ? 's' : ''} ago`
  } else if (elapsed < milliSecondsPerYear && units.indexOf(minimumUnit) <= units.indexOf('month')) {
    value = Math.round(elapsed / milliSecondsPerMonth)
    if (value < 1) return '< 1 month ago'
    return `${value} month${value > 1 ? 's' : ''} ago`
  } else {
    value = Math.round(elapsed / milliSecondsPerYear)
    if (value < 1) return '< 1 year ago'
    return `${value} year${value > 1 ? 's' : ''} ago`
  }
}
