import React, { useRef, useEffect, useState } from 'react'
import { StaticImage, GatsbyImage } from 'gatsby-plugin-image'

import linkedinFeed from '../content/linkedinFeed'
import timeDifference from '../utils/timeDifference'

const avatarUrl = '../images/linkedin/avatar.jpeg'

// TEMP: This is stupid, use GatsbyImage component with external GCP urls for images when available
// so no need for this hardcoded static approach
const tempRenderImage = (idx) => {
  if (idx === 0) {
    return (
      <StaticImage
        src={'../images/linkedin/01.jpg'}
        alt="LinkedIn post image"
        placeholder="none"
        layout="fixed"
        width={400}
      />
    )
  } else if (idx === 1) {
    return (
      <StaticImage
        src={'../images/linkedin/02.jpg'}
        alt="LinkedIn post image"
        placeholder="none"
        layout="fixed"
        width={400}
      />
    )
  } else if (idx === 2) {
    return (
      <StaticImage
        src={'../images/linkedin/03.jpg'}
        alt="LinkedIn post image"
        placeholder="none"
        layout="fixed"
        width={400}
      />
    )
  }
}

export default function LinkedInFeed() {
  const postsRef = useRef([])
  const [readMore, setReadMore] = useState({})

  const container = useRef()
  const containerFade = useRef()

  const setFadeOpacity = () => {
    const distToRight = container.current.scrollWidth - container.current.offsetWidth
    const fadeStartThreshold = 50

    if (container.current.scrollLeft >= distToRight - fadeStartThreshold) {
      containerFade.current.style.opacity = (distToRight - container.current.scrollLeft) / fadeStartThreshold
    } else {
      containerFade.current.style.opacity = 1
    }
  }

  useEffect(() => {
    const newReadMore = {}
    for (let i = 0; i < postsRef.current.length; i++) {
      const $post = postsRef.current[i]
      const $postText = $post.querySelector('.post-text')
      if ($postText.clientHeight > 40) {
        newReadMore[i] = true
      }
    }
    setReadMore(newReadMore)
  }, [linkedinFeed])

  /**
   * Timestamp is encoded in the post id, and can be decoded using the following:
   *
   * * Take the 19 digit ID from the URL, and (viewing it as a binary number) take the first 41 binary digits.
   * These 41 binary digits, encoded as an integer, are the unix timestamp of the post.
   *
   * Source: https://www.quora.com/How-can-you-find-the-actual-dates-of-posts-in-LinkedIn
   */
  const getPostTimestamp = (postId) => {
    return parseInt((+postId).toString(2).slice(0, 41), 2)
  }

  const showReadMore = (postIdx) => {
    if (readMore[postIdx]) {
      return <div className="read-more">Read more</div>
    }
  }

  return (
    <div className="linkedin-feed-wrapper">
      <div className="linkedin-feed-container" ref={container} onScroll={setFadeOpacity}>
        {linkedinFeed.map(({ postId, image, text }, idx) => {
          return (
            <a
              className="linkedin-feed-post"
              key={postId}
              ref={(el) => (postsRef.current[idx] = el)}
              href={`https://www.linkedin.com/feed/update/urn:li:activity:${postId}/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="content-wrapper">
                <div className="poster-detail">
                  <StaticImage
                    className="avatar"
                    src={avatarUrl}
                    alt="AdhereTech"
                    placeholder="none"
                    width={52}
                    layout="fixed"
                  />
                  <div className="poster-detail-text">
                    <div className="poster-name">AdhereTech</div>
                    <div className="post-date">{timeDifference(getPostTimestamp(postId))}</div>
                  </div>
                </div>
                <div className="post-text-wrapper">
                  <div className="post-text">{text}</div>
                </div>
                <div>{showReadMore(idx)}</div>
              </div>
              <div className="image-wrapper">{tempRenderImage(idx)}</div>
            </a>
          )
        })}
      </div>
      <div className="container-fade" ref={containerFade} />
    </div>
  )
}
